.home-page {
  .gallery {
    $gallery-max-width: conf('grid.max-width') - 20px;

    column-count: 3;
    column-fill: balance;
    column-gap: 0;
    margin: 0 auto 100px;
    max-width: conf('grid.max-width');
    text-align: center;

    @include media(#{'<=' + $gallery-max-width}) {
      margin: 0 -10px 100px;
      max-width: none;
    }

    @include media('<820px') {
      column-count: 2;
    }

    @include media('<414px') {
      display: none;
    }

    div {
      @include column-break(avoid);

      display: inline-block;
      overflow: hidden;
      padding: 2%;
      transition: all .1s ease-in-out;
    }

    img {
      display: block;
      width: 100%;
    }
  }

  .signature {
    margin-top: 30px;
  }

  .owner {
    margin-top: -50px;
  }

  .banners-grid {
    .banner:not(.has-auto-height) {
      height: 500px;
    }

    p {
      max-width: 547px;
      width: 100%;
    }
  }

  .intro-section {
    padding-top: 150px;
  }

  .owner-section {
    @include media('<725px') {
      .banner:first-child {
        padding: 100px 0 50px;
      }

      .banner:last-child {
        justify-content: flex-end;
      }

      img {
        max-width: 150%;
      }
    }

    @include media('<635px') {
      .banners-grid {
        justify-content: space-between;
      }

      .banner:first-child {
        width: 40%;
      }

      .banner:last-child {
        width: 60%;
      }
    }

    @include media('<575px') {
      .banners-grid {
        flex-direction: column;
        position: relative;

        .banner {
          width: 100%;
        }

        .banner:last-child {
          align-items: center;
        }

        .signature {
          @include position(absolute, null null 40px calc(50% - 140px));

          width: 120px;
        }

        .owner {
          margin-left: 35%;
          margin-top: 0;
          max-height: 400px;
          width: auto;
        }
      }
    }

    @include media('<350px') {
      .banners-grid .owner {
        max-width: 85%;
      }
    }

    h2 {
      margin-bottom: 30px;
    }
  }

  .gift-card-section {
    background: url('/images/home/gift-card.jpg') calc(50% - 250px) center no-repeat;

    @include media('<630px') {
      .banner:first-child {
        width: 40%;
      }

      .banner:last-child {
        width: 60%;
      }
    }
  }

  .vip-section {
    background: conf('color.beije') url('/images/home/vip.jpg') calc(50% + 400px) bottom no-repeat;

    @include media('<630px') {
      .banner:first-child {
        width: 60%;
      }

      .banner:last-child {
        width: 40%;
      }
    }
  }

  .gallery-section {
    .js-flickity {
      display: none;
      margin: 0;

      @include media('<414px') {
        display: block;
      }
    }

    .flickity-slider div {
      width: 90%;
    }

    .flickity-slider img {
      display: block;
      width: 100%;
    }
  }

  .hero-section {
    height: 670px;
    min-height: 670px;
    position: relative;

    .flickity-viewport {
      min-height: 670px;
    }

    .flickity-slider > div {
      @include size(100% 670px);

      max-height: 670px;
      padding-bottom: 0;
    }

    .content {
      @include position(absolute, 0 0 0 0);

      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      pointer-events: none;
      text-align: center;

      .button {
        pointer-events: all;
      }
    }
  }
}

@include media('<=800px') {
  .home-page .intro-section {
    padding-top: 93px;
  }

  .home-page .hero-section {
    $height: 500px;

    height: $height;
    min-height: $height;

    .flickity-viewport {
      min-height: $height;
    }

    .flickity-slider > div {
      height: $height;
      max-height: $height;
    }
  }
}

@include media('<768px') {
  .home-page .banner.is-huge {
    h1 {
      font-size: 80px;
      letter-spacing: 4px;
      line-height: 105px;
    }
  }
}

@include media('<575px') {
  .home-page .gift-card-section,
  .home-page .vip-section {
    background: none;

    .banners-grid {
      flex-direction: column;
      margin: 0;

      .banner {
        height: auto;
        padding: 50px 20px;
        width: 100%;

        &.is-image {
          height: 200px;
          padding: 0;
        }
      }
    }
  }

  .home-page .gift-card-section .is-image {
    background: url('/images/home/gift-card.jpg') center center no-repeat;
    background-size: auto 100%;
  }

  .home-page .vip-section {
    .is-image {
      background: conf('color.beije') url('/images/home/vip.jpg') center center no-repeat;
      background-size: auto 100%;
      order: -1;
    }
  }
}


@include media('<565px') {
  .home-page .banner.is-huge {
    h1 {
      font-size: 66px;
      letter-spacing: 3px;
      line-height: 80px;
    }
  }
}

@include media('<=480px') {
  .home-page .hero-section {
    $height: 420px;

    height: $height;
    min-height: $height;

    .flickity-viewport {
      min-height: $height;
    }

    .flickity-slider > div {
      height: $height;
      max-height: $height;
    }
  }
}

@include media('<440px') {
  .home-page .banner.is-huge {
    h1 {
      font-size: 54px;
      line-height: 58px;
    }
  }
}

@include media('<375px') {
  .home-page .banner.is-huge {
    h1 {
      font-size: 44px;
    }
  }
}

@include media('<360px') {
  .home-page .banner.is-huge {
    .content {
      padding: 0 20px;
    }

    .button {
      padding-left: 0;
      padding-right: 0;
      width: 100%;
    }
  }
}
