.file-input-wrapper {
  display: flex;
  flex-wrap: nowrap;
  position: relative;

  input {
    @include size(.1px);

    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }

  label {
    @extend %button;

    padding-left: 55%;
    padding-right: 0;
    position: relative;
    text-align: center;
    width: 100%;

    &:before {
      @include conf('font.montserrat');
      @include conf('type.input');
      @include position(absolute, 0 null 0 0);
      @include ellipsis;

      background: conf('color.white');
      border: 1px solid conf('color.black');
      color: conf('color.black');
      content: attr(data-filename);
      line-height: 50px;
      padding-left: 20px;
      text-align: left;
      width: 55%;
    }

    &.is-empty:before {
      color: conf('color.gray');
    }
  }

  input:focus + label,
  label:hover {
    @include conf('button.default.hover');
  }
}
