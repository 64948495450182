@include config-set('font', (
  montserrat: (
    font-family: ('Montserrat', 'sans-serif'),
    font-weight: 700,
    text-transform: uppercase
  ),

  times: (
    font-family: ('Times-Roman', 'serif')
  ),

  icons: (
    font-family: 'icons',
    display: inline-block,
    vertical-align: middle,
    -moz-osx-font-smoothing: grayscale,
    -webkit-font-smoothing: antialiased
  )
), true);
