.notification {
  background: conf('color.brown');
  color: conf('color.white');
  padding: 25px 20px;
  text-align: center;

  span {
    @include conf('font.montserrat');
    @include conf('type.small');

    display: block;

    + span {
      margin-top: 5px;
    }
  }

  .title,
  .editable.reveal,
  .editable:hover {
    color: conf('color.black');
  }

  &.has-single-value span {
    color: conf('color.white');
  }

  a,
  .link editable span {
    text-transform: none;
  }

  .link-ellipsed {
    display: inline-block;
    overflow: hidden;
    text-decoration: underline;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 60%;
  }
}
