@include config-set('color', (
  'white':         #fff,
  'black':         #333,
  'blacker':       #1a1915,
  'dark-blue':     #030822,
  'red':           #f00,

  'brown':         #bc8d4b,
  'light-brown':   #d5d3cb,
  'medium-brown':  #b28442,
  'dark-brown':    #987139,

  'dark-gray':     #858585,
  'gray':          #bababa,
  'light-gray':    #e3e0db,
  'lighter-gray':  #ececec,
  'lightest-gray': #f5f5f5,
  'beije':         #eeeae1,
  'error':         #f00,
  'disabled': (
    light: #dedede,
    dark:  #393939
  ),

  'admin-bar': (
    'button': (
      normal: (
        background: #555,
        color:      #fff
      ),

      disabled: (
        background: #333,
        color:      #555
      ),

      success: (
        background: #333,
        color:      #94f384
      )
    ),
  ),

  'editable': (
    'reveal':  #dcdcdc,
    'changed': #e3fbdf
  )
), true);

@include config-set('color.social', (
  facebook:   #3b5998,
  twitter:    #55acee,
  gplus:      #c53829,
  instagram:  #4090db,
  yelp:       #af0606,
  tock:       #2a2ae9,
  zomato:     #cb202d,
  foursquare: #f94877,
  resy:       rgb(255, 70, 45)
), true);

$color-classes: (
  'white',
  'black',
  'red',
  'brown',
  'gray',
  'light-gray',
  'lighter-gray',
  'lightest-gray',
  'blacker'
) !global;
