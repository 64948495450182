.intro-section {
  padding: 80px 0;
  text-align: center;

  &.is-hero .title {
    color: conf('color.brown');
  }

  &.is-inset {
    background: conf('color.white');
    margin: -100px auto 50px;
    max-width: 800px;
    padding-top: 43px;
    position: relative;
    z-index: 1;
  }

  .title,
  .subtitle,
  .description {
    margin: 0 auto;
  }

  .title,
  .description {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

  .title + .subtitle {
    margin-top: 20px;
  }

  .title + .description {
    margin-top: 30px;
  }

  .subtitle + .description {
    margin-top: 40px;
  }

  .description {
    max-width: 500px;
    padding-left: 20px;
    padding-right: 20px;

    &.is-small-text {
      @include conf('type.small');
    }

    &.is-medium {
      max-width: 505px;
    }

    &.is-large {
      max-width: 700px;
    }

    &.is-full-width {
      max-width: none;
    }

    + .description {
      margin-top: 40px;
    }

    + .link {
      display: inline-block;
      margin-top: 30px;
    }
  }

  .button {
    margin-top: 50px;
  }
}

@include media('<=800px') {
  .intro-section.is-inset {
    margin-top: 0;
  }
}

@include media('<660px') {
  .intro-section .title {
    font-size: 28px;
    line-height: 34px;
  }
}
