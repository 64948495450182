.team {
  @include unstyle-list;

  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 auto;
  max-width: 1600px;
}

.member {
  display: flex;
  flex: 1 1 100%;
  max-width: 1600px;
  position: relative;

  > * {
    display: flex;
    flex: 1 1 50%;
  }

  &.is-owner .image {
    display: block;
    position: relative;

    img {
      @include position(absolute, null null 0 null);

      display: block;
      max-width: 600px;
      pointer-events: none;
      width: 100%;
    }
  }

  .copy {
    flex: 1 1 auto;
    min-height: 520px;
    padding: 45px;

    h2,
    h3 {
      color: conf('color.brown');
    }

    h4 {
      margin-bottom: 40px;
    }
  }

  .image {
    background-position: center 20%;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;

    label {
      @include position(absolute, 0 0 0 0);

      display: none;
    }
  }

  input {
    @include position(absolute, 0 null null -10000px);
  }
}

// Fran Mathers
.member:first-child {
  background-color: conf('color.lightest-gray');

  .content {
    justify-content: flex-end;
    padding: 0 50px;
  }

  .copy {
    max-width: 600px;
    padding: 135px 0 115px;
    text-align: center;
    width: 100%;

    img {
      margin-top: 40px;
      pointer-events: none;
    }
  }

  .image {
    background-position: 15% bottom;
    background-size: auto 100%;
    margin-top: -50px;
  }
}

// Jesus Olivares
// .member:nth-child(2) {}

.member:nth-child(3),
.member:nth-child(4),
.member:nth-child(6),
.member:nth-child(7),
.member:nth-child(8),
.member:nth-child(9) {
  flex-basis: 50%;
  max-width: 800px;
}

// Ruben Peck & Joel Amador
.member:nth-child(3) .copy,
.member:nth-child(4) .copy {
  min-height: 260px;
}

// Lisa Ausin & Alyssa Christian
.member:nth-child(6) .content,
.member:nth-child(9) .content {
  background-color: conf('color.lightest-gray');
}

// Brent Gallagher
.member:nth-child(5) .content {
  background-color: conf('color.brown');

  .copy,
  .copy h3 {
    color: conf('color.white');
  }
}

@include media('<1366px') {
  // Joel Amador, Manuel Alcantra & Michael Hampton
  .member:nth-child(4) .content,
  .member:nth-child(7) .content,
  .member:nth-child(8) .content {
    background-color: conf('color.white');
  }

  // Lisa Ausin, Manuel Alcantra,
  // Michael Hampton & Alyssa Christian
  .member:nth-child(6),
  .member:nth-child(7),
  .member:nth-child(8),
  .member:nth-child(9) {
    flex-basis: 100%;
    max-width: none;
  }

  // Lisa Ausin
  .member:nth-child(7) .content {
    order: 1;
  }

  // Alyssa Christian
  .member:nth-child(8) .image {
    order: 1;
  }
}

@include media('<=1024px') {
  // Fran Mathers
  .member:first-child .image {
    background-position: left bottom;
  }
}

@include media('<=750px') {
  // Ruben Peck & Joel Amador
  .member:nth-child(3),
  .member:nth-child(4) {
    flex-basis: 100%;

    .copy {
      min-height: 0;
    }

    .image {
      min-height: 220px;
    }
  }

  // Joel Amador
  .member:nth-child(3) .image {
    order: 1;
  }
}

@include media('<=650px') {
  .member {
    flex-wrap: wrap;
  }

  .member .content,
  .member .image {
    flex-basis: 100%;
  }

  li.member:not(:first-child) .content { // scss-lint:disable QualifyingElement
    background-color: conf('color.white');
    order: 1;

    .copy {
      color: conf('color.black');
      min-height: 0;

      h3 {
        color: conf('color.brown');
      }
    }
  }

  .team .member .image {
    background-position: center 20%;
    height: 0;
    order: 0;
    padding-bottom: 50%;

    label {
      display: block;
    }
  }
}

.about-team-page .member.is-owner {
  @include media('<=1150px') {
    .image img {
      left: -40px;
    }
  }

  @include media('<=1100px') {
    .content {
      padding-left: 20px;
      padding-right: 20px;
    }

    .image img {
      left: -80px;
      width: 120%;
    }
  }

  @include media('<=960px') {
    .image img {
      left: -100px;
      width: 140%;
    }
  }

  @include media('<=830px') {
    .image img {
      left: -120px;
      width: 160%;
    }
  }
}

@include media('<=715px') {
  .about-team-page .member.is-owner {
    flex-direction: column;

    .content,
    .image {
      flex: 1 1 auto;
      width: 100%;
    }

    .content {
      order: 1;
    }

    .copy {
      max-width: none;
      min-height: 0;
      padding: 50px 0;
      position: relative;

      img {
        @include position(absolute, null null calc(100% + 30px) calc(50% - 140px));

        width: 120px;
      }
    }

    .image {
      flex-basis: 350px;
      padding-bottom: 0;
      text-align: center;

      img {
        display: inline-block;
        margin-left: 25%;
        max-height: 350px;
        position: static;
        width: auto;
      }
    }
  }
}

@include media('<=660px') {
  main > .intro-section:first-child {
    padding-top: 20px;
  }

  .about-team-page .member {
    h2 {
      font-size: 28px;
      line-height: 34px;
    }
  }
}

@include media('<490px') {
  .about-team-page .copy {
    padding-left: 25px;
    padding-right: 25px;
    width: 100%;

    p {
      display: none;
    }
  }

  .about-team-page .copy label {
    display: block;
    position: relative;

    &:before,
    &:after {
      @include position(absolute, 7px 0 null null);

      background: conf('color.brown');
      content: '';
    }

    &:before {
      @include size(17px 1px);

      margin-top: 8px;
    }

    &:after {
      @include size(1px 17px);

      margin-right: 8px;
    }

    h3 {
      padding-right: 30px;
    }
  }

  .about-team-page .member > .content input:checked ~ p {
    display: block;
  }

  .about-team-page .copy :checked + label:after {
    display: none;
  }
}
