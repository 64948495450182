header {
  @include position(fixed, 0 0 null 0);

  background: conf('color.white');
  z-index: 3;

  input,
  > label {
    display: none;
  }

  .brand {
    @include position(absolute, 0 null null 50%);
    @include size(143px conf('height.header'));
    @include hide-text;

    background: url('/images/logo.png') center center no-repeat;
    background-size: 143px 40px;
    display: block;
    transform: translateX(-50%);
    transition: all .2s ease-in-out;
    z-index: 1;

    @include media('retina2x') {
      background-image: url('/images/logo@2x.png');
      background-size: 143px 40px;
    }
  }

  > nav {
    transition: height .2s ease-in-out;
  }

  nav a,
  nav label {
    @include conf('font.montserrat');
    @include conf('type.h4');

    color: conf('color.black');
    display: block;
    line-height: conf('height.header');
    padding: 0;
    padding-right: 40px;
    position: relative;
    transition: color .15s ease-in-out;
  }

  ul {
    @include unstyle-list(true);
    @include g-container;

    align-items: center;
    display: flex;
    overflow: hidden;

    // sub-header
    nav {
      @include position(fixed, conf('height.header') 0 0 0);
      @include use-height(sub-header);

      background: conf('color.black');
      display: none;
      transition: opacity .15s ease-in-out;

      &::before,
      &::after {
        @include position(absolute, 100% 0 null 0);
        @include size(auto 45px);

        content: '';
      }

      &::before {
        bottom: 100%;
        height: 10px;
        top: auto;
      }

      a,
      label {
        @include conf('type.h5');

        color: conf('color.gray');
        line-height: conf('height.sub-header');
      }

      a:hover,
      label:hover {
        color: conf('color.white');
      }
    }
  }

  li {
    flex: 0 0 auto;
    position: relative;

    html:not(.touch) &:hover {
      nav {
        display: block;
      }
    }

    &:hover > a,
    &:hover > label {
      color: conf('color.brown');
    }

    &.has-subitems {
      label {
        cursor: default;
      }

      // The bottom border
      > label::before {
        @include position(absolute, null 60px 0 0);

        background: conf('color.brown');
        content: '';
        height: 0;
        transition: height .2s ease-in-out;
      }

      // The chevron
      > label::after {
        @include conf('font.icons');

        content: conf('icons.chevron');
        font-size: 25px;
        margin-left: -5px;
      }
    }

    &:last-child > a,
    &:last-child > label {
      padding-right: 0;
    }
  }

  .has-subitems {
    &:first-child > label::before {
      left: 0;
    }

    &:hover {
      // The bottom border
      > label::before {
        height: 5px;
      }
    }

    &.is-hidden nav {
      opacity: 0;
    }

    &.is-open nav {
      display: block;
    }
  }

  .spacer {
    flex-grow: 1;
  }
}

@include media('>=1024px') {
  .touch header .has-subitems:hover nav {
    display: block;
  }
}

.logged-in {
  header {
    top: conf('height.admin-bar');

    ul nav {
      top: conf('height.header');
    }
  }
}

.backdropfilter header {
  @include backdrop-filter(blur(10px));

  background: rgba(conf('color.white'), .85);
}
