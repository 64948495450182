@include keyframes(spin) {
  from {
    transform: rotateZ(0);
  }

  to {
    transform: rotateZ(360deg);
  }
}

.loading-indicator {
  @include size(15px);

  animation: spin 1s linear infinite;
  background: url('/images/loading.png');
  background-size: 15px;
  display: inline-block;
  padding: 0;
  vertical-align: middle;
}
