// About
.about-vip-page main > .banner.is-hero:first-child {
  background-image: url('/images/about/vip/hero.jpg');

  @include media('<=658px') {
    background-image: url('/images/about/vip/hero-small.jpg');
  }
}

.about-our-story-page main > .banner.is-hero:first-child {
  background-image: url('/images/about/our-story/hero.jpg');

  @include media('<=658px') {
    background-image: url('/images/about/our-story/hero-small.jpg');
  }
}

.about-careers-page main > .banner.is-hero:first-child {
  background-image: url('/images/about/careers/hero.jpg');

  @include media('<=658px') {
    background-image: url('/images/about/careers/hero-small.jpg');
  }
}

.about-scholarships-page main > .banner.is-hero:first-child {
  background-image: url('/images/about/scholarships/hero.jpg');

  @include media('<=658px') {
    background-image: url('/images/about/scholarships/hero-small.jpg');
  }
}

.about-merchandise-page main > .banner.is-hero:first-child {
  background-image: url('/images/about/merchandise/hero.jpg');

  @include media('<=658px') {
    background-image: url('/images/about/merchandise/hero-small.jpg');
  }
}

.about-press-and-awards-page main > .banner.is-hero:first-child {
  background-image: url('/images/about/press-and-awards/hero.jpg');

  @include media('<=658px') {
    background-image: url('/images/about/press-and-awards/hero-small.jpg');
  }
}


// Menus
.menus-page-lunch main > .banner.is-hero:first-child {
  background-image: url('/images/menus/lunch/hero.jpg');

  @include media('<=658px') {
    background-image: url('/images/menus/lunch/hero-small.jpg');
  }
}

.menus-page-dinner main > .banner.is-hero:first-child {
  background-image: url('/images/menus/dinner/hero.jpg');

  @include media('<=658px') {
    background-image: url('/images/menus/dinner/hero-small.jpg');
  }
}

.menus-page-desserts main > .banner.is-hero:first-child {
  background-image: url('/images/menus/desserts/hero.jpg');

  @include media('<=658px') {
    background-image: url('/images/menus/desserts/hero-small.jpg');
  }
}

.menus-page-drinks main > .banner.is-hero:first-child {
  background-image: url('/images/menus/drinks/hero.jpg');

  @include media('<=658px') {
    background-image: url('/images/menus/drinks/hero-small.jpg');
  }
}

.menus-page-children main > .banner.is-hero:first-child {
  background-image: url('/images/menus/children/hero.jpg');

  @include media('<=658px') {
    background-image: url('/images/menus/children/hero-small.jpg');
  }
}

.menus-page-sunday-brunch main > .banner.is-hero:first-child {
  background-image: url('/images/menus/brunch/hero.jpg');


  @include media('<=658px') {
    background-image: url('/images/menus/brunch/hero-small.jpg');
  }

  .is-large { max-width: 600px; }
}

// Other
.private-rooms-page main > .banner.is-hero:first-child {
  background-image: url('/images/private-rooms/hero.jpg');

  @include media('<=658px') {
    background-image: url('/images/private-rooms/hero-small.jpg');
  }
}

.gift-card-page main > .banner.is-hero:first-child {
  background-image: url('/images/gift-card/hero.jpg');

  @include media('<=658px') {
    background-image: url('/images/gift-card/hero-small.jpg');
  }
}

.reservations-page main > .banner.is-hero:first-child {
  background-image: url('/images/reservations/hero.jpg');

  @include media('<=658px') {
    background-image: url('/images/reservations/hero-small.jpg');
  }
}

.contacts-page main > .banner.is-hero:first-child {
  background-image: url('/images/contacts/hero.jpg');

  @include media('<=658px') {
    background-image: url('/images/contacts/hero-small.jpg');
  }
}

.thank-you-page main > .banner.is-hero:first-child {
  background-image: url('/images/thank-you/hero.jpg');

  @include media('<=658px') {
    background-image: url('/images/thank-you/hero-small.jpg');
  }
}

.error-404-page main > .banner {
  background-image: url('/images/404/hero.jpg');

  @include media('<=658px') {
    background-image: url('/images/404/hero-small.jpg');
  }
}
