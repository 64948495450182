.banner {
  @include use-height(banner);

  align-items: center;
  background-position: center center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;

  &.is-light {
    color: conf('color.white');
  }

  &.is-hero {
    @include use-height(banner-hero);

    padding-left: 20px;
    padding-right: 20px;
  }

  &.is-huge {
    height: calc(100vh - #{conf('height.header')});
    max-height: 580px;
    min-height: 320px;

    h1 {
      @include conf('type.h1-huge');
    }

    .button {
      padding-left: 100px;
      padding-right: 100px;
    }
  }

  &.has-auto-height {
    height: auto;
  }

  > * {
    flex: 0 1 auto;
  }

  h1,
  h2 {
    margin-bottom: 10px;
    max-width: 750px;
    width: 100%;

    &.is-medium {
      max-width: 505px;
    }
  }

  &.is-hero,
  &.is-huge {
    h1,
    h2 {
      margin-bottom: 30px;
    }
  }

  h4 {
    margin-bottom: 30px;
  }

  p {
    max-width: 400px;
    width: 100%;

    &.is-small {
      max-width: 300px;
    }

    &.is-medium {
      max-width: 505px;
    }

    &.is-large {
      max-width: 700px;
    }
  }

  .button {
    margin-top: 40px;
  }
}

.banners-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  .banner {
    flex: 1 1 auto;
    width: 50%;
  }

  .price {
    @include conf('font.montserrat');
    @include conf('type.h4');

    display: inline-block;
    margin-top: 30px;
  }
}

@include media('<660px') {
  .banner.is-hero h1 {
    font-size: 44px;
    letter-spacing: 4px;
    line-height: 44px;
  }
}
