.is-option-list {
  .options {
    display: flex;
    flex-direction: column;
  }

  > label + .options {
    margin-top: 10px;
  }

  &.is-horizontal .options {
    flex-direction: row;

    label + label {
      margin: 0 0 0 15px;
    }
  }

  label {
    display: block;
    overflow: hidden;
    position: relative;

    + label {
      margin-top: 15px;
    }
  }

  input {
    @include position(absolute, 0 null null -200%);
  }

  span {
    @include conf('font.montserrat');
    @include conf('type.input');

    display: block;
    min-height: 20px;
    padding-left: 30px;
    position: relative;

    &:before {
      @include position(absolute, 3px null null 0);
      @include size(20px);

      box-shadow: inset 0 0 0 1px conf('color.blacker');
      content: '';
      transition: all .15s ease-in-out;
    }

    &:after {
      @include position(absolute, 8px null null 5px);
      @include size(10px);

      background: conf('color.blacker');
      content: '';
      transform: scale(0);
      transition: all .15s ease-in-out;
    }
  }

  .is-radio span {
    &:before {
      border-radius: 10px;
    }

    &:after {
      border-radius: 5px;
    }
  }

  .is-checkbox span:after {
    @include conf('font.icons');
    @include size(20px);


    background: none;
    color: conf('color.blacker');
    content: conf('icons.checkmark');
    font-size: 20px;
    -moz-osx-font-smoothing: auto;
    -webkit-font-smoothing: auto;
    left: 0;
    line-height: 20px;
    top: 3px;
    transform-origin: center 60%;
  }

  input:checked + span:after {
    transform: scale(1);
  }

  span:hover:before,
  input:focus + span:before {
    box-shadow: inset 0 0 0 3px conf('color.blacker');
  }
}
