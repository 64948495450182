.gift-card-page {
  form {
    margin: 0 auto 100px;

    @include media('<=340px') {
      margin-left: 20px;
      margin-right: 20px;
    }
  }

  .e-gift-section {
    border-top: 1px solid conf('color.light-gray');
    margin: 0 auto 40px;
    max-width: 600px;
    padding-left: 100px;
    padding-right: 100px;

    @include media('<=640px') {
      margin: 0 20px;
      max-width: none;
    }

    @include media('<=580px') {
      padding-left: 0;
      padding-right: 0;

      .title {
        margin-left: 50px;
        margin-right: 50px;
      }
    }

    .button {
      margin-bottom: 20px;
    }
  }

  .error-msg {
    @include conf('type.small');
    color: conf('color.error');
  }

  .field-captcha {
    margin-top: 25px;
  }
}
