// #307ED0

.admin-bar {
  @include position(fixed, 0 0 null 0);

  align-items: center;
  background: conf('color.black');
  color: conf('color.white');
  display: flex;
  font-size: 0;
  height: conf('height.admin-bar');
  justify-content: space-between;
  padding: 0 20px;
  z-index: 1;

  @include media('<=425px') {
    justify-content: flex-end;

    h5 {
      display: none;
    }
  }

  h5 {
    display: inline-block;
    margin-right: 10px;
  }

  a,
  button {
    @include conf('font.montserrat');
    @include conf('type.small');
    @include conf('color.admin-bar.button.normal');

    border: 0;
    display: inline-block;
    margin-left: 2px;
    padding: 10px;

    &:disabled {
      @include conf('color.admin-bar.button.disabled');
    }

    &:focus {
      outline: none;
    }

    &.success {
      @include conf('color.admin-bar.button.success');

      padding-left: 22px;
      position: relative;
    }
  }

  span {
    @include conf('font.montserrat');
    @include conf('type.small');

    display: inline-block;
    padding: 10px 10px 10px 0;
    text-transform: none;
  }

  .loading-indicator {
    margin: -3px 3px 0 0;
  }

  .icon-checkmark {
    @include position(absolute, 50% null null 0);

    font-size: 22px;
    margin-top: -11px;
  }
}
