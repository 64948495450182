.form-container,
form {
  max-width: 300px;
  text-align: left;

  .fields + .fields {
    margin-top: 80px;
  }

  &.is-inline .fields {
    align-items: stretch;
    display: flex;
    flex-direction: row;

    .field {
      flex: 1 1 auto;

      + .field {
        margin-left: -1px;
        margin-top: 0;
      }
    }
  }

  .form-title {
    margin-bottom: 30px;
    text-transform: none;

    + p {
      margin-bottom: 40px;
      margin-top: -30px;
    }
  }

  .field {
    &.is-dropdown .input-container {
      position: relative;

      &::after {
        @include position(absolute, null 5px 0 null);
        @include conf('font.icons');
        @include use-height(50px, true);

        content: conf('icons.chevron');
        font-size: 35px;
        pointer-events: none;
      }
    }

    + .field,
    + .field-group {
      margin-top: 25px;
    }

    > label {
      @include conf('font.montserrat');
      @include conf('type.label');

      color: conf('color.dark-gray');
      display: block;
      font-weight: 400;
      text-transform: none;
    }

    .message {
      @include conf('type.small');

      color: conf('color.error');
      margin-top: 2px;
    }

    &.has-error > label,
    &.has-error > .message {
      color: conf('color.error');
    }

    &.is-disabled {
      pointer-events: none;

      > label {
        color: conf('color.disabled.light');
      }

      #{$all-text-inputs},
      select {
        border-color: conf('color.disabled.light');

        @include placeholder {
          color: conf('color.disabled.light');
        }
      }
    }
  }

  #{$all-text-inputs},
  select,
  rg-date .field {
    @include conf('font.montserrat');
    @include conf('type.input');
    @include size(100% 50px);

    appearance: none;
    background: none;
    border: 1px solid conf('color.black');
    border-radius: 0;
    padding-left: 15px;
    transition: box-shadow .15s ease-in-out;

    @include placeholder {
      color: conf('color.gray');
    }
  }

  select {
    &::-ms-expand {
      display: none;
    }

    &:focus::-ms-value {
      background: transparent;
      color: conf('color.black');
    }
  }

  #{$all-text-inputs-focus},
  #{$all-text-inputs-hover},
  select:hover,
  select:focus,
  rg-date .field:hover,
  rg-date .field:focus,
  rg-date .container.open .field {
    box-shadow: inset 0 0 0 3px config-get('color.black');
    outline: none;
  }

  textarea {
    height: auto;
    padding: 10px 20px;
    resize: vertical;
    text-indent: 0;
  }

  rg-date,
  rg-date .container {
    display: block;
    width: 100%;
  }

  rg-date .field {
    @include size(100% 50px);

    border-color: conf('color.black');
    padding: 0;
  }

  .actions {
    margin-top: 40px;
  }

  .field-group {
    display: flex;

    .field {
      flex: 1 1 auto;
      margin: 0;

      + .field {
        margin-left: -1px;
      }
    }

    + .field {
      margin-top: 25px;
    }

    &.is-zip-state {
      .field:first-child {
        flex-basis: 40%;
      }

      .field:last-child {
        flex-basis: 60%;
      }
    }
  }
}

.touch label > * {
  pointer-events: none;
}
