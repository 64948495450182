.products {
  @include unstyle-list;
  @include g-container;

  column-count: 1;
  column-fill: auto;
  column-gap: 15px;
  margin-bottom: 100px;

  @include media('>=560px') {
    column-count: 2;
  }

  @include media('>=960px') {
    column-count: 3;
  }

  li {
    @include column-break(avoid);

    background: conf('color.white');
    display: inline-block;
    margin-bottom: 15px;
    width: 100%;
  }

  .image {
    background-position: center center;
    background-size: cover;
    min-height: 260px;

    &.is-tall {
      min-height: 378px;
    }
  }

  .content {
    padding: 30px;
  }

  h3 {
    margin-bottom: 20px;
  }

  .price {
    @include conf('type.h5');
    @include conf('font.montserrat');

    display: block;
    margin-top: 70px;

    em {
      color: conf('color.gray');
      font-style: normal;
      font-weight: normal;
    }
  }
}
