@font-face {
  $fonts-base-path: conf('paths.fonts');

  font-family: 'icons';
  src: url('#{$fonts-base-path}/icons/icons.eot');
  src: url('#{$fonts-base-path}/icons/icons.eot?#iefix') format('eot'),
       url('#{$fonts-base-path}/icons/icons.woff') format('woff'),
       url('#{$fonts-base-path}/icons/icons.ttf') format('truetype'),
       url('#{$fonts-base-path}/icons/icons.svg#icons') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^='icon-'],
[class*=' icon-'] {
  display: inline-block;
  font-family: 'icons';
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
}

.icon-arrow-left:before {
  content: conf('icons.arrow-left');
}

.icon-arrow-right:before {
  content: conf('icons.arrow-right');
}

.icon-checkmark:before {
  content: conf('icons.checkmark');
}

.icon-chevron-up:before {
  content: conf('icons.chevron-up');
}

.icon-chevron:before {
  content: conf('icons.chevron');
}

.icon-facebook:before {
  content: conf('icons.facebook');
}

.icon-foursquare:before {
  content: conf('icons.foursquare');
}

.icon-gplus:before {
  content: conf('icons.gplus');
}

.icon-instagram:before {
  content: conf('icons.instagram');
}

.icon-open-table:before {
  content: conf('icons.open-table');
}

.icon-resy:before {
  content: conf('icons.resy');
}

.icon-tock:before {
  content: conf('icons.tock');
}

.icon-twitter:before {
  content: conf('icons.twitter');
}

.icon-yelp:before {
  content: conf('icons.yelp');
}

.icon-zomato:before {
  content: conf('icons.zomato');
}

