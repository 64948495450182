$networks: (
  '.facebook':   conf('color.social.facebook'),
  '.twitter':    conf('color.social.twitter'),
  '.gplus':      conf('color.social.gplus'),
  '.instagram':  conf('color.social.instagram'),
  '.yelp':       conf('color.social.yelp'),
  '.tock':       conf('color.social.tock'),
  '.zomato':     conf('color.social.zomato'),
  '.foursquare': conf('color.social.foursquare'),
  '.resy':       conf('color.social.resy')
);

#{map-keys($networks)} {
  transition-duration: .15s;
  transition-property: background-color, border-color;
  transition-timing-function: ease-in-out;
}

@each $network, $color in $networks {
  #{$network}:hover {
    background-color: $color;
    border-color: $color;
  }
}
