%button {
  @include conf('font.montserrat');
  @include conf('type.h4');
  @include conf('button.default');

  border: 0;
  display: inline-block;
  text-transform: uppercase;
  transition-duration: .15s;
  transition-property: box-shadow, color, background-color;
  transition-timing-function: ease-in-out;

  &:hover {
    @include conf('button.default.hover');
  }

  &.is-large {
    @include conf('button.large');
  }

  &.is-wide {
    @include conf('button.wide');
  }

  &.is-outlined {
    @include conf('button.outlined');

    &:hover {
      @include conf('button.outlined.hover');
    }
  }

  &.is-inverted {
    @include conf('button.inverted');

    &:hover {
      @include conf('button.inverted.hover');
    }

    &:disabled,
    &.is-disabled {
      @include conf('button.inverted.disabled');
    }

    &.is-outlined {
      @include conf('button.inverted.outlined');

      &:hover {
        @include conf('button.inverted.outlined.hover');
      }

      &:disabled,
      &.is-disabled {
        @include conf('button.inverted.outlined.disabled');
      }
    }
  }

  &:disabled,
  &.is-disabled {
    @include conf('button.disabled');

    &.is-outlined {
      @include conf('button.outlined.disabled');
    }
  }

  &.is-hero {
    @include conf('button.hero');

    &:hover {
      @include conf('button.hero.hover');
    }
  }
}

.button,
button {
  @extend %button;
}

@include media('<360px') {
  .button,
  button {
    @include wide-button;

    &.is-disabled,
    &.is-inverted,
    &.is-large,
    &.is-outlined,
    &.is-wide {
      @include wide-button;
    }
  }
}
