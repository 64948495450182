.date-picker {
  display: flex;

  .field {
    flex: 1 1 30%;

    &:last-child {
      flex-basis: 40%;
    }

    + .field {
      margin: 0 0 0 -1px;
    }
  }
}
