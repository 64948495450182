.chess-board {
  @include unstyle-list;

  align-content: stretch;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding-bottom: 110px;
  text-align: center;

  li {
    display: flex;
    flex: 0 1 50%;
    min-width: 250px;
    padding: 10px 0;

    &:nth-child(2n) .content {
      background-color: conf('color.white');
      margin-left: 10px;
      margin-right: 0;
    }
  }

  .content {
    background: conf('color.lighter-gray');
    flex: 1 1 auto;
    margin-right: 10px;
    padding: 40px;
  }
}

// 2 Columns
@include media('>=670px') {
  .chess-board li {
    .content {
      background-color: conf('color.white');
    }

    &:nth-child(4n) .content,
    &:nth-child(4n-3) .content {
      background-color: conf('color.lighter-gray');
    }
  }
}

// 1 Column
@include media('<670px') {
  .chess-board {
    li {
      flex-basis: 100%;

      &:nth-child(2n) .content {
        background-color: conf('color.lighter-gray');
        margin-left: 0;
      }
    }

    .content {
      background-color: conf('color.white');
      margin-right: 0;
    }
  }
}
