@mixin use-height($element, $line-height: false) {
  $height: $element;

  @if type-of($element) == 'string' {
    $height: conf(#{'height.' + $element});
  }

  @if $height != null {
    height: $height;

    @if $line-height {
      line-height: $height;
    }
  }
}

@mixin unstyle-list($is-nav: false) {
  list-style: none;
  margin: 0;
  padding: 0;

  @if $is-nav {
    font-size: 0;

    li {
      display: inline-block;
      font-size: 1rem;
    }
  }
}

@mixin g-container($max-width: conf('grid.max-width')) {
  margin: 0 auto;
  max-width: $max-width;

  @include media(#{'<=' + ($max-width + conf('grid.side-margin') * 2) }) {
    margin: 0 conf('grid.side-margin');
    max-width: none;
  }
}

@mixin conf($args...) {
  $value: conf($args...);

  @if type-of($value) == 'map' {
    $keys: map-keys($value);

    @each $key in $keys {
      $property-value: map-get($value, $key);

      @if type-of($property-value) != 'map' {
        #{$key}: $property-value;
      }
    }
  }
}

@mixin backdrop-filter($filter) {
  -webkit-backdrop-filter: $filter;
  backdrop-filter: $filter; // scss-lint:disable PropertySpelling
}

@mixin column-break($mode) {
  // scss-lint:disable Indentation
  -webkit-column-break-inside: $mode;
     -moz-column-break-inside: $mode;
          column-break-inside: $mode;
}

@mixin wide-button {
  padding-left: 0;
  padding-right: 0;
  width: 100%;
}
