html {
  background-color: conf('color.blacker');
}

body {
  @include conf('font.times');

  background-color: conf('color.white');
  font-size: 16px;

  // Remove phpdebugbar inline padding
  padding-bottom: 0 !important; // scss-lint:disable ImportantRule
  padding-top: conf('height.header');
  transition: padding-top .2s ease-in-out;

  .logged-in & {
    padding-top: #{conf('height.header') + conf('height.admin-bar')};
  }
}

@include media('<=1024px') {
  body {
    padding-top: conf('height.mobile-header');
  }

  .logged-in body {
    padding-top: #{conf('height.mobile-header') + conf('height.admin-bar')};
  }
}

main {
  overflow: hidden;
}

a {
  text-decoration: none;
}

.price {
  white-space: nowrap;
}

.powered-by {
  @include conf('font.montserrat');
  @include conf('type.small');

  color: conf('color.black');
  text-transform: none;

  span {
    margin-left: 10px;
  }
}

.open-table-logo {
  @include hide-text;
  @include size(100px 23px);

  background: url('/images/reservations/open-table-logo.png');
  display: inline-block;
  vertical-align: middle;

  @include media('retina2x') {
    background-image: url('/images/reservations/open-table-logo@2x.png');
    background-size: 100px 23px;
  }
}
