$headings: h1, h2, h3, h4, h5;

#{$headings} {
  @include conf('font.montserrat');

  margin: 0;
}

@each $heading in $headings {
  #{$heading} {
    @include conf(#{'type.'+$heading});
  }
}

h1 small,
h2 small {
  @include conf('type.h4');

  display: block;
}

h1 em,
h2 em {
  color: conf('color.brown');
  font-style: normal;
}

p {
  @include conf('type.p');

  + p {
    margin-top: 20px;
  }
}
