rg-date {
  .container .calendar {
    @include conf('font.montserrat');

    border: 0;
    cursor: default;
    margin: -1px 0 0;
    padding: 0;
    width: 364px;

    @include media('<=364px') {
      width: 100%;
    }
  }

  .grid-row,
  .grid-wrap {
    border-color: conf('color.blacker');
    border-style: solid;
    border-width: 0 1px;
  }

  // < month, year >
  .grid-row:nth-child(2) {
    @include conf('type.h5');

    background: conf('color.brown');
    border: 0;
    color: conf('color.white');
    height: 50px;
    justify-content: space-between;

    .selector {
      @include size(50px);

      color: conf('color.brown');
      cursor: pointer;
      flex-basis: 50px;
      font-size: 0;
      position: relative;

      &:hover {
        background: conf('color.dark-brown');
      }

      &:after {
        @include position(absolute, 50% null null 50%);
        @include conf('font.icons');

        color: conf('color.white');
        font-size: 30px;
      }

      &:first-child:after {
        content: conf('icons.chevron');
        transform: translate(-50%, -50%) rotate(90deg);
      }

      &:last-child:after {
        content: conf('icons.chevron');
        transform: translate(-50%, -50%) rotate(-90deg);
      }
    }

    .month {
      flex-basis: auto;
      text-transform: none;
    }
  }

  // Weekdays
  .grid-row:nth-child(3) {
    @include use-height(60px, true);
    @include conf('type.small');

    color: conf('color.brown');
    padding: 0 10px;

    .day-name {
      font-weight: 400;
    }
  }

  // Month days
  .grid-wrap {
    border-width: 0 1px 1px;
    padding: 0 10px 10px;

    .date {
      border: 0;
      cursor: pointer;
      transition: all .1s ease-in-out;

      &:hover {
        background: conf('color.light-gray');
      }

      &.selected {
        background: conf('color.brown');
        border: 0;
        color: conf('color.white');
      }
    }

    .today {
      border: 0;
      position: relative;

      &:after {
        @include position(absolute, null null 0 50%);
        @include size(12px 1px);

        background: conf('color.brown');
        content: '';
        transform: translateX(-50%);
        transition: opacity .15s ease-in-out;
      }

      &:hover:after {
        opacity: 0;
      }
    }
  }

  &.hide-year .calendar .grid-row:first-child {
    display: none;
  }
}

