.awards-grid {
  margin-top: 100px;

  .content {
    padding: 20px 20px 70px;
    position: relative;
    text-align: left;
  }

  h3 {
    color: conf('color.brown');
    font-weight: 400;
  }

  p {
    min-height: 90px;
  }

  a {
    @include position(absolute, null null 30px 20px);
  }
}

@include media('>=780px') {
  .awards-grid {
    li {
      flex-basis: 33.33%;

      .content,
      &:nth-child(2n) .content {
        margin-left: 0;
        margin-right: 20px;
      }

      &:nth-child(3n) .content {
        margin-right: 0;
      }

      .content,
      &:nth-child(2n) .content,
      &:nth-child(4n) .content,
      &:nth-child(4n-3) .content {
        background-color: conf('color.lighter-gray');
      }

      &:nth-child(2) .content,
      &:nth-child(2n+4) .content {
        background-color: conf('color.white');
      }
    }
  }
}
