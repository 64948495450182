.about-careers-page {
  body {
    background-color: conf('color.lightest-gray');
  }

  .intro-section:first-child {
    border-bottom: 1px solid conf('color.light-gray');
    margin: 0 auto;
    max-width: 600px;
    padding-bottom: 40px;
  }

  .intro-section .description + .description {
    margin-top: 85px;
  }

  .form-section {
    padding: 100px 0 0;

    .intro-section .title {
      text-transform: none;
    }
  }

  form {
    margin: 0 auto;
    max-width: 360px;
    padding-bottom: 50px;
  }

  .download-form-section {
    margin: 20px auto 0;
    max-width: 600px;
    padding: 50px 0 120px;
    position: relative;

    &:after {
      @include position(absolute, 0 20px null 20px);

      border-bottom: 1px solid conf('color.light-gray');
      content: '';
    }

    p {
      margin: 0 auto;
      max-width: 460px;
    }
  }

  .error-msg {
    @include conf('type.small');
    color: conf('color.error');
    max-width: 360px;
    margin: 0 auto;
    text-align: left
  }

  .field-captcha {
    margin-top: 25px;
  }
}
