@include config-set('type', (
  h1: (
    font-size: 72px,
    line-height: 74px,
    letter-spacing: 4px
  ),

  h1-huge: (
    font-size: 110px,
    line-height: 134px,
    letter-spacing: 6px
  ),

  h2: (
    font-size: 36px,
    line-height: 44px,
    letter-spacing: .4px
  ),

  h3: (
    font-size: 24px,
    line-height: 32px,
    letter-spacing: .6px
  ),

  h4: (
    font-size: 14px,
    line-height: 22px,
    letter-spacing: .4px,
  ),

  h5: (
    font-size: 14px,
    line-height: 32px,
    letter-spacing: .2px,
    font-weight: 400
  ),

  p: (
    font-size: 1rem,
    line-height: 30px,
    letter-spacing: .2px,
    margin: 0
  ),

  small: (
    font-size: 12px,
    line-height: 20px,
    letter-spacing: .3px,
    font-weight: 400
  ),

  label: (
    font-size: 10px,
    line-height: 22px,
    letter-spacing: .5px,
    font-weight: 400,
    text-transform: none
  ),

  input: (
    font-size: 13px,
    line-height: 26px,
    letter-spacing: .5px,
    font-weight: 400,
    text-transform: none
  )
), true);
