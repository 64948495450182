footer {
  @include conf('font.montserrat');

  background: conf('color.blacker');
  color: conf('color.white');
  padding: 70px 0;

  article {
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;

    > nav {
      flex: 1 1 100%;
      margin-right: 60px;
      max-width: 540px;

      input {
        display: none;
      }
    }

    &:last-child {
      .payments {
        display: none;
      }
    }
  }

  .payments {
    @include size(175px 29px);

    background: url('/images/payments.png');
    background-size: 175px 29px;
    flex: 0 0 175px;
    margin-top: 50px;

    @include media('retina2x') {
      background-image: url('/images/payments@2x.png');
    }
  }

  .contacts {
    flex: 0 0 245px;

    label {
      @include conf('type.h4');

      display: block;
    }

    a {
      color: conf('color.white');
    }
  }

  .phone {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 30px;

    a {
      font-size: 30px;
      margin-top: 5px;
    }
  }

  .button {
    margin-bottom: 30px;
  }

  .social {
    margin-top: 50px;

    label {
      display: block;
      margin-bottom: 15px;
    }

    .networks {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    a {
      @include size(32px);

      align-items: center;
      border: 2px solid conf('color.white');
      display: flex;
      font-size: 26px;
      justify-content: center;
      line-height: 30px;
      text-align: center;

      i {
        margin-top: -1px;
      }
    }

    .zomato i {
      font-size: 28px;
      margin-left: -7px;
    }

    .tock i {
      font-size: 18px;
    }
  }

  .copy-credits {
    @include conf('type.small');

    display: flex;
    flex-basis: 100%;
    flex-direction: row;
    font-weight: 400;
    justify-content: space-between;
    margin-top: 50px;
    position: relative;
    text-transform: none;

    .copy {
      color: conf('color.light-gray');
    }

    .credits {
      color: conf('color.gray');
      display: block;
      flex-basis: 245px;
    }
  }

  ul {
    @include unstyle-list;

    display: flex;
    flex-direction: row;
    justify-content: space-between;

    ul {
      flex-direction: column;

      li {
        margin: 0 0 10px;
      }
    }

    label {
      @include conf('type.h4');

      color: conf('color.white');
      cursor: default;
      display: block;
      margin-bottom: 15px;
    }

    .top-level a {
      @include conf('type.h4');

      color: conf('color.gray');
    }

    a {
      @include conf('type.small');

      color: conf('color.gray');
      transition: color .15s ease-in-out;

      &:hover {
        color: conf('color.white');
      }
    }
  }
}

@include media('<720px') {
  footer article {
    flex-wrap: wrap;

    > nav {
      margin-bottom: 50px;
      margin-right: 0;

      .payments {
        display: none;
      }
    }

    .social {
      margin-top: 0;
    }

    &:last-child {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;

      .payments {
        display: block;
      }
    }
  }

  footer .copy-credits {
    display: block;
  }
}

@include media('<470px') {
  footer article:last-child {
    flex-direction: column;

    .payments {
      flex-basis: 29px;
      order: 0;
    }

    .copy-credits {
      margin-top: 20px;
      order: 1;
    }
  }
}

@include media('<400px') {
  footer article > nav {
    display: none;
  }
}
