@include media('<1024px') {
  header {
    min-height: conf('height.mobile-header');
    z-index: 3;

    > nav {
      align-items: flex-start;
      display: flex;
      justify-content: flex-start;

      > ul {
        flex: 1 1 auto;
        padding-bottom: 50px;
        padding-top: 50px;
      }
    }

    nav {
      > ul {
        display: none;
      }

      ul {
        align-items: stretch;
        flex: 1 1 auto;
        flex-direction: column;
        margin: 0;
        padding: 30px 0;

        nav {
          background: conf('color.light-gray');
          position: static;
        }
      }

      li {
        flex: 0 0 auto;
      }

      .has-subitems > label {
        text-indent: 20px;

        &:after {
          text-indent: 0;
        }
      }

      .spacer {
        display: none;
      }

      a,
      label {
        line-height: 50px;
        padding: 0;
        text-align: center;
      }
    }

    ul nav a {
      color: conf('color.black');

      &:hover {
        color: conf('color.brown');
      }
    }

    .brand {
      @include use-height(mobile-header);

      background-size: 90px 27px;
    }

    > label {
      @include position(absolute, 0 20px null null);
      @include size(30px conf('height.mobile-header'));

      background: none;
      border: 0;
      display: block;
      padding: 0;

      &:hover {
        background: none;
        border: 0;
      }

      span {
        @include position(absolute, 50% null null 0);
        @include size(100% 2px);

        background-color: conf('color.black');
        display: block;
        font-size: 0;
        margin-top: -2px;
        transition: background-color .3s;
        user-select: none;

        &:before,
        &:after {
          @include position(absolute, null null null 0);
          @include size(100%);

          background: conf('color.black');
          content: '';
          transition: transform .3s;
        }

        &:before {
          transform: translateY(-300%);
        }

        &:after {
          transform: translateY(300%);
        }
      }
    }

    // Open menu
    [type=checkbox]:checked {
      + label span {
        background-color: transparent;

        &:before {
          transform: translateY(0) rotate(45deg);
        }

        &:after {
          transform: translateY(0) rotate(-45deg);
        }
      }

      ~ nav {
        height: calc(100vh - #{conf('height.mobile-header')});
        margin-top: conf('height.mobile-header');

        ul {
          display: flex;
          max-height: 100%;
          overflow-y: auto;
        }
      }
    }

    .has-subitems:hover nav {
      display: none;
    }

    .has-subitems {
      [type='checkbox']:checked ~ nav {
        display: flex;
        height: auto;
        margin: 0;
      }

      > label:before,
      > label:after {
        display: none;
      }
    }
  }

  .backdropfilter header ~ nav {
    @include backdrop-filter(blur(10px));

    background: rgba(conf('color.white'), .85);
  }
}
