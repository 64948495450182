@include config-set('icons', (
  arrow-left: '\EA01',
  arrow-right: '\EA02',
  checkmark: '\EA03',
  chevron-up: '\EA04',
  chevron: '\EA05',
  facebook: '\EA06',
  foursquare: '\EA07',
  gplus: '\EA08',
  instagram: '\EA09',
  open-table: '\EA0A',
  resy: '\EA0B',
  tock: '\EA0C',
  twitter: '\EA0D',
  yelp: '\EA0E',
  zomato: '\EA0F',
), true);