.about-our-story-page {
  @include media('<=615px') {
    .philosophy-banner {
      height: 0;
      padding-bottom: 77%;
    }
  }

  .intro-section.is-inset {
    margin-bottom: 30px;
    padding-top: 65px;
  }

  .philosophy-section .description {
    max-width: 630px;
  }
}
