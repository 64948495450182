@include config-set('button', (
  default: (
    background: config-get('color.black'),
    color: config-get('color.white'),
    padding: 16px 60px 12px,

    hover: (
      background-color: #525047
    )
  ),

  large: (
    padding: 20px 100px 18px
  ),

  wide: (
    padding-left: 0,
    padding-right: 0,
    text-align: center,
    width: 100%
  ),

  outlined: (
    background: none,
    border: 5px solid config-get('color.black'),
    color: config-get('color.black'),
    padding: 10px 60px 8px,

    hover: (
      box-shadow: inset 0 0 0 3px config-get('color.black')
    ),

    disabled: (
      background: none,
      border-color: config-get('color.disabled.light'),
      color: config-get('color.disabled.light')
    )
  ),

  inverted: (
    background: config-get('color.white'),
    color: config-get('color.black'),

    hover: (
      background-color: #e3e0db
    ),

    disabled: (
      background-color: config-get('color.disabled.dark'),
      color: #1a1915
    ),

    outlined: (
      background: none,
      border-color: config-get('color.white'),
      color: config-get('color.white'),

      hover: (
        box-shadow: inset 0 0 0 3px config-get('color.white')
      ),

      disabled: (
        background: none,
        border-color: config-get('color.disabled.dark'),
        color: config-get('color.disabled.dark')
      )
    )
  ),

  disabled: (
    background-color: config-get('color.disabled.light'),
    color: config-get('color.white'),
    pointer-events: none
  ),

  hero: (
    background-color: config-get('color.medium-brown'),
    color: config-get('color.white'),

    hover: (
      background-color: config-get('color.brown')
    )
  )
), true);
