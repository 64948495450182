.private-rooms-page {
  .open-gallery {
    cursor: pointer;
  }

  .banners-grid {
    margin-bottom: 80px;

    .banner {
      min-height: 500px;
    }
  }

  main > .banner + .intro-section {
    padding: 100px 0;
  }

  .banners {
    .banner {
      height: auto;
    }

    .intro-section.is-inset {
      padding-top: 93px;
    }
  }

  main > .intro-section {
    padding: 60px 0;

    &.has-divider {
      border-top: 1px solid conf('color.gray');
      margin-left: auto;
      margin-right: auto;
      max-width: 600px;
    }

    .description {
      max-width: 520px;

      &.is-large {
        max-width: 670px;
      }
    }

    .subtitle {
      max-width: 670px;
    }
  }

  form,
  .form-container {
    margin: 0 auto 120px;
  }

  main > .book-room-section {
    background: conf('color.dark-blue');
    color: conf('color.white');
    margin-bottom: 50px;
    padding: 125px 20px;

    .title {
      color: conf('color.brown');
    }
  }

  #tripleseat_embed_form {
    #lead_form_submit {
      text-align: left;

      .button,
      a {
        float: none;
      }

      a {
        display: block;
        margin-top: 20px;
      }
    }
  }

  table {
    h2 {
      font-size: 24px;
      line-height: 32px;
      letter-spacing: 0.6px;
    }

    label {
      @include conf('font.montserrat');
      @include conf('type.label');

      color: conf('color.dark-gray');
      display: block;
      font-weight: 400;
      text-transform: none;
    }
  }
}
