.reservations-page {
  main {
    text-align: center;
  }

  .banner.is-hero {
    min-height: 580px;

    h1 {
      margin: 10px 0 40px;
    }

    .button {
      margin: 0 0 15px;
    }
  }

  .open-table-winner {
    @include hide-text;
    @include size(164px 114px);

    background: url('/images/reservations/open-table-winner.png');

    @include media('retina2x') {
      background-image: url('/images/reservations/open-table-winner@2x.png');
      background-size: 164px 114px;
    }
  }

  section {
    padding: 100px 0;
  }

  .operating-hours {
    margin: 0 auto;
    max-width: 600px;
    padding: 30px 0;

    p {
      margin: 0 auto 30px;
      max-width: 380px;
    }
  }




  .hours-section {
    max-width: 255px;
    margin: 0 auto;
    strong {
      display: inline-block;
    }

    strong,
    p {
      margin-bottom: 10px;
    }

    ul {
      @include unstyle-list;
    }

    li {
      @include conf('type.p');

      display: flex;
      justify-content: space-between;      
      text-align: left; 

      span {
        display: block;
      }

      .day {
        flex: 1 1 60%;
        padding-right: 15px;
      }

      .hour {
        flex: 0 0 40%;
        min-width: 100px;
      }
    }
  }

}

@include media('<=540px') {
  .reservations-page .hours-list dl {
    flex-basis: 50%;
  }
}

@include media('<420px') {
  .reservations-page .banner.is-hero {
    height: auto;
    padding: 40px 20px;

    .open-table-winner {
      @include size(120px 80px);

      background: url('/images/reservations/open-table-winner.png');
      background-size: 100% auto;

      @include media('retina2x') {
        background-image: url('/images/reservations/open-table-winner@2x.png');
        background-size: 100% auto;
      }
    }

    h1 {
      @include conf('type.h2');

      font-size: 34px;
    }
  }
}
