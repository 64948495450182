.menus-page {
  $max-width: strip-unit(conf('grid.max-width'));
  $columns-gap: 230 * 100 / $max-width * 1%;

  .banners-grid {
    margin-bottom: 100px;
    text-align: center;

    .banner {
      padding: 50px 25px;
    }

    h2 {
      max-width: 505px;

      @include media('<=550px') {
        margin-left: 20px;
        margin-right: 20px;
        max-width: none;
      }
    }
  }

  main {
    article {
      display: flex;
    }

    .cta-download {
      article { display: block; }

      &.intro-section .description {
        max-width: 550px;
      }
    }
  }

  aside {
    flex: 1 1 50%;
    padding: 0 #{$columns-gap / 2} 0 0;

    + aside {
      padding: 0 0 0 #{$columns-gap / 2};
    }

    section {
      margin-bottom: 80px;

      &:last-child {
        margin-bottom: 120px;
      }
    }

    h3,
    .options li {
      align-items: flex-start;
      display: flex;
      justify-content: space-between;

      // scss-lint:disable SelectorDepth
      .title {
        flex: 1 1 auto;
        padding-right: 20px;
      }

      span {
        display: block;
      }
      // scss-lint:enable SelectorDepth
    }

    h3 {
      small {
        font-size: 1rem;
        font-weight: normal;
      }

      + p {
        margin-top: 12px;
      }
    }
  }

  .menu-title {
    margin-bottom: 140px;
    text-align: center;
  }

  .section-title {
    color: conf('color.brown');
    margin-bottom: 50px;
  }

  .section-description {
    margin: -50px 0 50px;
  }

  .menu-entry {
    + .menu-entry {
      margin-top: 50px;
    }

    p {
      max-width: 80%;
    }
  }

  .options {
    @include unstyle-list;

    margin: 10px 0 0;

    li {
      font-weight: bold;

      + li {
        margin-top: 10px;
      }
    }

    .price {
      @include conf('font.montserrat');
    }
  }

  .notes {
    max-width: 80%;
  }
}

@for $i from 1 through 20 {
  .menus-page .banners-grid .banner {
    &:nth-child(#{$i}) {
      order: $i;
    }
  }
}

.menus-page-drinks {
  .banners-grid {
    margin-bottom: 0;

    .banner {
      height: auto;
      min-height: 500px;
    }

    h2 {
      max-width: 400px;
    }

    .is-large {
      max-width: 630px;
    }
  }
}

.menus-page-sunday-brunch {
  .section-title {
    margin-bottom: 30px;
  }

  .banner .powered-by {
    margin-top: 15px;
  }
}

@include media('<760px') {
  .menus-page .banners-grid {
    flex-direction: column;

    .banner {
      height: auto;
      min-height: 400px;
      width: 100%;
    }
  }

  .menus-page .menu-title {
    @include conf('type.h3');

    margin-bottom: 50px;
    padding: 0 25px;

    small {
      @include conf('type.h4');
    }
  }

  .menus-page main article {
    display: block;
    padding-bottom: 60px;

    aside {
      display: block;
      padding: 0 5px;
      width: 100%;

      + aside {
        margin-top: 60px;
        padding: 0 5px;
      }
    }

    section {
      margin-bottom: 0;

      + section {
        margin-top: 60px;
      }
    }

    .menu-entry + .menu-entry {
      margin-top: 40px;
    }
  }

  .menus-page aside .section-title {
    @include conf('type.h3');

    margin-bottom: 30px;
  }

  .menus-page aside .section-description {
    margin-top: -20px;
  }

  .menus-page aside h3 .price,
  .menus-page .options li .price {
    @include conf('type.h4');

    font-weight: normal;
  }

  @each $i in (14, 10, 6, 2) {
    .menus-page .banners-grid:not(.is-inverted) .banner {
      &:nth-child(#{$i}) {
        order: #{$i - 2};
      }
    }
  }

  @each $i in (4, 8, 12) {
    .menus-page .banners-grid.is-inverted .banner {
      &:nth-child(#{$i}) {
        order: #{$i - 2};
      }
    }
  }
}

@include media('<660px') {
  .menus-page .banners-grid .banner h2 {
    font-size: 28px;
    line-height: 34px;
  }
}

@include media('<=560px') {
  .menus-page-sunday-brunch .banner.is-hero p {
    letter-spacing: 0;

    .last {
      display: none;
    }
  }
}
