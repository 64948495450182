.about-vip-page {
  .banner.is-hero h1 {
    max-width: 500px;
  }

  form {
    margin: 0 auto;
  }

  .legal-section {
    padding-bottom: 120px;
    padding-top: 40px;
  }

  .error-msg {
    @include conf('type.small');
    color: conf('color.error');;
  }
}
