.align-center {
  text-align: center;
}

.align-left {
  text-align: left;
}

.align-right {
  text-align: right;
}

.f-align-center {
  align-items: center;
}

.f-align-left {
  align-items: flex-start;
}

.f-align-right {
  align-items: flex-end;
}
