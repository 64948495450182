.login-page {
  background: conf('color.white');

  body {
    transition: none;
  }

  form {
    margin: 0 auto;
    max-width: 400px;
  }
}
