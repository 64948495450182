.contacts-page {
  .form-section article {
    display: flex;
    justify-content: flex-start;
    padding-top: 90px;
  }
  .error-msg {
    @include conf('type.small');
    color: conf('color.error');
  }

  .form-container {
    #errorExplanation {
      margin-top: 40px;
      max-width: 420px;
    }

    #tripleseat_embed_form {
      margin-top: 40px;
      max-width: 360px;

      #lead_form_submit {
        text-align: left;

        .button,
        a {
          float: none;
        }

        a {
          display: block;
          margin-top: 20px;
        }
      }
    }

    table {
      h2 {
        font-size: 24px;
        line-height: 32px;
        letter-spacing: 0.6px;
      }

      label {
        @include conf('font.montserrat');
        @include conf('type.label');

        color: conf('color.dark-gray');
        display: block;
        font-weight: 400;
        text-transform: none;
      }
    }
  }

  main .form-container {
    flex: 1 1 525px;
    max-width: 525px;
    min-width: 415px;

    #{$all-text-inputs},
    select,
    .input-container {
      max-width: 360px;
    }
  }

  aside {
    display: flex;
    flex: 1 1 440px;
    flex-direction: column;
    max-width: 440px;

    &:last-child {
      padding-right: 0;
    }

    section + section {
      margin-top: 40px;
    }

    .phone-section {
      h3 {
        text-transform: none;
      }

      a {
        @include conf('font.montserrat');
        @include conf('type.h3');
      }
    }

    .address-section {
      display: flex;
      justify-content: space-between;

      div + div {
        margin-left: 20px;
      }

      div:last-child {
        flex-shrink: 0;
      }
    }

    .hours-section {
      max-width: 255px;
    }

    strong {
      display: inline-block;
    }

    strong,
    p {
      margin-bottom: 10px;
    }

    ul {
      @include unstyle-list;
    }

    li {
      @include conf('type.p');

      display: flex;
      justify-content: space-between;

      span {
        display: block;
      }

      .day {
        flex: 1 1 70%;
        padding-right: 15px;
      }

      .hour {
        flex: 0 0 30%;
        min-width: 100px;
      }
    }

    .social {
      a {
        color: conf('color.black');
      }

      i {
        color: conf('color.brown');
        display: inline-block;
        font-size: 30px;
        margin-right: 10px;
        vertical-align: middle;
      }
    }
  }

  .form-title {
    text-transform: none;
  }

  .book-section {
    margin-top: 90px;

    article {
      border-top: 1px solid conf('color.light-gray');
      padding-top: 90px;
    }

    .button {
      margin-bottom: 20px;
    }
  }

  // scss-lint:disable IdSelector
  #map {
    @include size(100% 600px);

    margin-top: 30px;
  }
  // scss-lint:enable IdSelector
}

// scss-lint:disable IdSelector
.touch.contacts-page #map {
  display: none;
}
// scss-lint:enable IdSelector

@include media('<830px', '>=715px') {
  .contacts-page aside .address-section {
    flex-direction: column;

    div + div {
      margin-left: 0;
      margin-top: 30px;
    }
  }
}

@include media('<715px') {
  .contacts-page main article {
    flex-direction: column;

    .form-container,
    aside {
      flex-basis: auto;
    }
  }

  .contacts-page main .form-container {
    margin-bottom: 70px;
  }
}

@include media('<390px') {
  .contacts-page main .form-container {
    flex: 1 1 auto;
    max-width: none;
    min-width: 0;
  }

  .contacts-page aside .address-section {
    flex-direction: column;

    div + div {
      margin-left: 0;
      margin-top: 30px;
    }
  }
}
