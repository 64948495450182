.link {
  color: conf('color.brown');
  position: relative;

  &:after {
    @include position(absolute, null 0 -3px 0);

    background: conf('color.brown');
    content: '';
    height: 1px;
  }

  &.is-inverted {
    color: conf('color.white');

    &:after {
      background: conf('color.white');
    }
  }
}
