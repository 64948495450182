.about-scholarships-page {
  .banners-grid .banner {
    height: auto;
    min-height: 540px;
    padding: 50px 45px;
  }

  .banners-grid .banner:nth-child(2) {
    background-color: #c4c7c8; // scss-lint:disable ColorVariable
    background-position: center center;
    background-repeat: no-repeat;
    background-size: auto;
  }
}

@include media('<900px') {
  .about-scholarships-page .banners-grid {
    flex-direction: column;

    .banner {
      flex: 1 1 auto;
      min-height: 0;
      padding-left: 20px;
      padding-right: 20px;
      width: 100%;

      &:nth-child(2) {
        order: -1;
      }

      &.is-image {
        height: 0;
        padding-bottom: 54.51%;
      }
    }
  }
}

@include media('<750px') {
  .about-scholarships-page .banner h2 {
    @include conf('type.h3');
  }
}

@include media('<490px') {
  .about-scholarships-page .banner h1 {
    @include conf('type.h2');
  }
}

@include media('<400px') {
  .about-scholarships-page .banners-grid .banner.is-image {
    height: 270px;
    padding-bottom: 0;
  }
}

@include media('<385px') {
  .about-scholarships-page .button {
    @include wide-button;
  }
}
