.flickity-slider > div {
  background-position: center center;
  background-size: cover;
}

.banner.has-carousel {
  .flickity-viewport {
    max-height: 540px;
  }

  .flickity-slider > div {
    @include size(100% 0);

    background-size: cover;
    max-height: 540px;
    padding-bottom: 44.888%;
  }

  .flickity-prev-next-button {
    @include size(80px 100px);

    border-radius: 0;
    bottom: 0;
    padding: 0;
    top: auto;
    transform: none;
    z-index: 2;

    &:hover,
    &:active {
      background-color: conf('color.light-gray');
      opacity: 1;
    }

    &:after {
      @include conf('font.icons');

      color: conf('color.brown');
      font-size: 25px;
    }

    &.previous {
      left: calc(50% - 400px);

      &:after {
        content: conf('icons.arrow-left');
      }
    }

    &.next {
      left: auto;
      right: calc(50% - 400px);

      &:after {
        content: conf('icons.arrow-right');
      }
    }

    svg {
      display: none;
    }
  }

  .flickity-page-dots {
    bottom: 33px;
    left: 50%;
    line-height: 12px;
    padding: 10px 15px;
    transform: translateX(-50%);
    width: auto;
    z-index: 2;

    .dot {
      @include size(10px);

      background: none;
      border: 1px solid conf('color.brown');
      margin: 0 8px;
      opacity: 1;
      transition: all .15s ease-in-out;

      &:hover {
        background-color: conf('color.light-gray');
        border-color: conf('color.light-gray');
      }

      &.is-selected {
        background: conf('color.brown');
        border-color: conf('color.brown');
      }
    }
  }
}

@include media('<=800px') {
  .banner.has-carousel {
    .flickity-prev-next-button {
      display: none;
    }

    .flickity-page-dots {
      bottom: -70px;
    }
  }
}
