.jobs-grid {
  h3 {
    margin-bottom: 20px;
    text-transform: none;
  }

  p {
    margin-bottom: 40px;
  }
}
