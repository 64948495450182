.about-merchandise-page {
  body {
    background-color: conf('color.lightest-gray');
  }

  .banner {
    h1 {
      max-width: none;
    }
  }
}

@include media('<490px') {
  .about-merchandise-page .banner h1 {
    @include conf('type.h2');
  }
}
